
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require("trix")
require("@rails/actiontext")

require('jquery')
require("@nathanvda/cocoon")

import "bootstrap";
import * as bootstrap from 'bootstrap';
import "../stylesheets/application";
import "@fortawesome/fontawesome-free/js/all";

Rails.start()
Turbolinks.start()
ActiveStorage.start()
